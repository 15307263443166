
//数据库表明_字段名，
// export const batchID = [
//   {value: 0,
//   label: '个人申请'
// }, {
//   value: 1,
//     label: '批量申请'
// }]
export const register_type_dict = {
  true: '律师', false: '访客' }

export const deparment_type_dict = {
  true: '律所', false: '律协' }

export const deparment_type_options = [
  {
    value: true,
    label: '律所'
  }, {
    value: false,
    label: '律协'
  }]



export const dynamicScore_type_options = [
  {
    value: true,
    label: '协会导入'
  }, {
    value: false,
    label: '其他'
  }]


export const register_type_options = [
  {
    value: true,
    label: '签到成功'
  }, {
    value: false,
    label: '签到失败'
  }]
export const train_type_dict = {false: '其他培训', true: '职业道德培训',null:'-'}
export const train_type_options = [
  {
    value:false,
    label: '其他培训'
  },
  {
    value:true,
    label: '职业道德培训'
  }]
export const train_consumer_dict = { false: '所有人都可报名参加', true: '只对本律所律师开放'}
export const train_consumer_options = [
  {
    value: false,
    label: '所有人都可报名参加'
  }, {
    value: true,
    label: '只对本律所律师开放'
  }]

export const train_status_dict = { 0: '待审核', 1: '不通过',2: '已通过'}
export const train_status_options = [
  {
    value: 0,
    label: '待审核'
  }, {
    value: 1,
    label: '不通过'
  }, {
    value: 2,
    label: '已通过'
  }]


export const user_politicalStatus_dict = { 0: '群众', 1: '中国共产主义青年团团员', 2: '中国共产党预备党员',3: '中国共产党党员',
  4: '中国国民党革命委员会会员', 5: '中国民主建国会会员', 6: '中国民主同盟盟员',7: '九三学社社员', 8: '中国农工民主党党员', 9: '中国民主促进会会员',
  10: '中国致公党党员',11: '无党派人士', 12: '其它'}

export const user_politicalStatus_options = [
  {
    value: 0,
    label: '群众'
  }, {
    value: 1,
    label: '中国共产主义青年团团员'
  }, {
    value: 2,
    label: '中国共产党预备党员'
  }, {
    value: 3,
    label: '中国共产党党员'
  },
  {
    value: 4,
    label: '中国国民党革命委员会会员'
  }, {
    value: 5,
    label: '中国民主建国会会员'
  }, {
    value: 6,
    label: '中国民主同盟盟员'
  }, {
    value: 7,
    label: '九三学社社员'
  },
  {
    value: 8,
    label: '中国农工民主党党员'
  }, {
    value: 9,
    label: '中国民主促进会会员'
  }, {
    value: 10,
    label: '中国致公党党员'
  }, {
    value: 11,
    label: '无党派人士'
  },
  {
  value: 12,
  label: '其它'
}]

export const user_gender_dict = { false: '男', true:'女' }
export const user_gender_options = [
  {
    value: false,
    label: '男'
  }, {
    value: true,
    label: '女'
  }]

export const user_degree_dict = { 0: '大专', 1: '本科', 2: '硕士',3: '博士', 4: '双学士', 5: '其他' }
export const user_degree_options = [
  {
    value: 0,
    label: '大专'
  }, {
    value: 1,
    label: '本科'
  }, {
    value: 2,
    label: '硕士'
  }, {
    value: 3,
    label: '博士'
  }, {
    value: 4,
    label: '双学士'
  },
  {
    value: 5,
    label: '其它'
  }]

export const user_nationality_dict = {0: '汉族', 1: '蒙古族', 2: '回族',3: '藏族', 4: '维吾尔族', 5: '苗族', 6: '彝族', 7: '壮族', 8: '其它'}
export const user_nationality_options = [
  {
    value: 0,
    label: '汉族'
  }, {
    value: 1,
    label: '蒙古族'
  }, {
    value: 2,
    label: '回族'
  }, {
    value: 3,
    label: '藏族'
  }, {
    value: 4,
    label: '维吾尔族'
  }, {
    value: 5,
    label: '苗族'
  }, {
    value: 6,
    label: '彝族'
  }, {
    value: 7,
    label: '壮族'
  }, {
    value: 8,
    label: '其他'
  }]


// export const train_type_dict = {false: '其他培训', true: '职业道德培训',null:'-'}
export const application_type_dict = { 0: '培训学时', 1: '公益法律服务', 2:'职业道德学时'}
//留一个做实验
//这里为了关联下面的detail，只能取 0，1
export const application_type_options = [
  {
    value: 0,
    label: '培训学时'
  }, {
    value: 1,
    label: '公益法律服务'
  },
  {
    value: 2,
    label: '职业道德学时'
  },
]

export const application_status_dict = { 0: '待审核', 1: '律所不通过', 2: '律所通过，协会待审核', 3: '协会不通过', 4: '协会通过' }
export const application_status_options = [
  {
    value: 0,
    label: '待审核'
  },
  {
    value: 1,
    label: '律所不通过'
  }, {
    value: 2,
    label: '律所通过，协会待审核'
  }, {
    value: 3,
    label: '协会不通过'
  }, , {
    value: 4,
    label: '协会通过'
  }
]

//培训课时------------------------------
export const application_detailTrain_dict =  {
  0: '由司法部、中华全国律师办会单独或会同国务院所属部、委、办联合举办的进修课程、法律研讨会',
  1: '由国务院各部、委、办单独或联合举办的法律专业进修课程、法律研讨会',
  2: '由中国法学会及其名市、自治区法学会和各全国性专业、专门法学研究会举办的论坛、进年度累计实际完成座、研讨会',
  3: '参加中华全国律师协会或其他地方律师协会单独或联合举办的集中培训、法律专业进修课程、法律研讨',
  4: '各类律师学院举办的法律专业进修课程、法律研讨会',
  5:'在公开发行的专业刊物上发表论立或者有专业论文被编入公开出版发行的论文集，或在律师论坛等全国性学术会议上发表论文',
  6:'在《海南律师》内部杂志上发表论文',
  7:'参加各级党派组织的汇总活动或人大、政协、政府部门、社会团体、行业协会组织的学习和培训活动',
  8:'其他'}
// 8:'职业道德、执业纪律(年度累计课时至少 8 课时)',

export const application_detailTrain_options = [
  {
    value: 0,
    label: '由司法部、中华全国律师办会单独或会同国务院所属部、委、办联合举办的 \n' +
      '进修课程、法律研讨会'
  },
  {
    value: 1,
    label: '由国务院各部、委、办单独或联合举办的法律专业进修课程、法律研讨会'
  },
  {
    value: 2,
    label: '由中国法学会及其名市、自治区法学会和各全国性专业、专门法学研究会举 \n' +
      '办的论坛、进年度累计实际完成座、研讨会'
  },
  {
    value: 3,
    label: '参加中华全国律师协会或其他地方律师协会单独或联合举办的集中培训、法 \n' +
      '律专业进修课程、法律研讨'
  },

  {
    value: 4,
    label: '各类律师学院举办的法律专业进修课程、法律研讨会'
  },
  {
    value: 5,
    label: '在公开发行的专业刊物上发表论立或者有专业论文被编入公开出版发行的论 \n' +
      '文集，或在律师论坛等全国性学术会议上发表论文'
  },

  {
    value: 6,
    label: '在《海南律师》内部杂志上发表论文'
  },
  {
    value: 7,
    label: '参加各级党派组织的汇总活动或人大、政协、政府部门、社会团体、行业协 \n' +
      '会组织的学习和培训活动'
  },

  // {
  //   value: 8,
  //   label: '职业道德、执业纪律(年度累计课时至少 8 课时)'
  // },
  {
    value: 8,
    label: '其他'
  }]

export const application_detailEight_dict = {
  0: '职业道德、执业纪律(年度累计课时至少 8 课时)'
}

export const application_detailEight_options = [
  {
    value: 0,
    label: '职业道德、执业纪律(年度累计课时至少 8 课时)'
  }
]


//公益时长------------------------------

export const application_detailPublic_dict =  {
  0: '办理法律援助案例',
  1: '担任村(居)法律顾问',
  2: '参加普法宣传会',
  3: '参加“1+1”法律援助志愿者活动',
  4: '义务法律咨询',
  5:'协助党政机关开展法律服务工作',
  6:'参加民营企业法治体验等活动',
  7:'调解矛盾纠纷',
  8:'参加学术交流',
  9:'为公益法律活动服务提供资金汇总工作',
  10:'从事其他形式法律服务工作'}
export const application_detailPublic_options = [
  {
    value: 0,
    label: '办理法律援助案例 '
  },
  {
    value: 1,
    label: '担任村(居)法律顾问 '
  },

  {
    value: 2,
    label: '参加普法宣传'
  },
  {
    value: 3,
    label: '参加“1+1”法律援助志愿者活动 '
  },

  {
    value: 4,
    label: '义务法律咨询 '
  },
  {
    value: 5,
    label: '协助党政机关开展法律服务工作'
  },

  {
    value: 6,
    label: '参加民营企业法治体验等活动'
  },
  {
    value: 7,
    label: '调解矛盾纠纷 '
  },

  {
    value: 8,
    label: '参加学术交流'
  },
  {
    value: 9,
    label: '为公益法律活动服务提供资金汇总工作 '
  },
  {
    value: 10,
    label: '从事其他形式法律服务工作'
  }]

